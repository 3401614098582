import { AbcReportV3SortFieldStrict } from '@typings';

export const ABC_REPORT_SORT_FIELD: AbcReportV3SortFieldStrict = {
  PRODUCT_NAME: 'PRODUCT_NAME',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  PROFIT: 'PROFIT',
  PROFIT_GROUP: 'PROFIT_GROUP',
  PROFIT_PERCENT: 'PROFIT_PERCENT',
  REVENUE: 'REVENUE',
  REVENUE_GROUP: 'REVENUE_GROUP',
  REVENUE_PERCENT: 'REVENUE_PERCENT',
  SECTION_NAME: 'SECTION_NAME',
  UNIT_TYPE: 'UNIT_TYPE',
  ITEMS_COUNT: 'ITEMS_COUNT',
  ITEMS_COUNT_GROUP: 'ITEMS_COUNT_GROUP',
  ITEMS_COUNT_PERCENT: 'ITEMS_COUNT_PERCENT',
  ORDERS_COUNT: 'ORDERS_COUNT',
  ORDERS_COUNT_GROUP: 'ORDERS_COUNT_GROUP',
  ORDERS_COUNT_PERCENT: 'ORDERS_COUNT_PERCENT',
};
